import React from 'react';
import Faq from '../Faq/Faq';
import { Trans } from 'gatsby-plugin-react-i18next';
import { FaqType } from '../../types/faq.types';

const FAQS: Array<FaqType> = [
  {
    question: <Trans>Как избежать идентификации?</Trans>,
    answer: (
      <>
        <p>
          <Trans>
            Веб-сайты собирают информацию при помощи снятия браузерных отпечатков, и в дальнейшем отслеживают вашу активность и идентифицируют среди других посетителей.
          </Trans>
        </p>
        <p>
          <Trans>
            Octo Browser заменяет данные о вашем настоящем устройстве, предоставляя безопасный виртуальный профиль. Таким образом, ваш фингерпринт становится неотличим от отпечатка браузера любого другого пользователя интернета.</Trans>
        </p>
      </>
    ),
  },
  {
    question: <Trans>Использовать прокси и vpn недостаточно?</Trans>,
    answer: (
      <>
        <p>
          <Trans>
            К сожалению, нет. Смена IP-адреса — это первый и необходимый шаг для
            анонимности в сети, но часто недостаточный. Современные сайты
            тщательно отслеживают и анализируют любую доступную информацию о
            устройстве пользователя, а манипуляции с IP могут интерпретироваться
            ими как вредоносная активность.
          </Trans>
        </p>
        <p>
          <Trans>
            Octo Browser позволяет привязать виртуальный профиль к любому
            популярному типу прокси (https, socks5, ssh) и избежать
            дополнительных проверок и блокировок со стороны сайтов.
          </Trans>
        </p>
      </>
    ),
  },
  {
    question: <Trans>Преимущества мультиаккаунтинга</Trans>,
    answer: (
      <>
        <p>
          <Trans>
            Интернет-сервисы стремятся связать между собой и проанализировать
            всю вашу активность в сети. Для получения доступа к большинству из
            них необходима регистрация, кроме того, часть из них ограничивает
            возможность ведения нескольких аккаунтов. О конфиденциальности в
            таком случае не может быть и речи, не говоря уже о рисках потери
            персональных данных.
          </Trans>
        </p>
        <p>
          <Trans>
            Octo Browser позволяет скрыть ваш цифровой след, предоставляя
            множество отдельных аккаунтов для доступа к каждому сервису. Обмен
            или утечка данных между виртуальными профилями невозможна, что
            обеспечивает полную анонимность в сети.
          </Trans>
        </p>
      </>
    ),
  },
];

type FaqAnonymityProps = {
  title: JSX.Element;
};
const FaqAnonymity = ({ title }: FaqAnonymityProps): React.ReactElement => {
  return <Faq isWhite faqs={FAQS} title={title} />;
};

export default FaqAnonymity;
