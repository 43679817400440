import React from 'react';
import { graphql } from 'gatsby';
import { Trans } from 'gatsby-plugin-react-i18next';
import Layout from '../../layouts';
import DownBlock from '../../components/DownBlock/DownBlock';
import AppRating from '../../components/AppRating';
import SolutionLayout from '../../components/Solutions/SolutionLayout/SolutionsLayout';
import headSrc from '../../assets/images/solutions/anonymity/head.png';
import headSrc2x from '../../assets/images/solutions/anonymity/head_2x.png';
import Description1 from '../../assets/images/solutions/anonymity/description_1.svg';
import Description2 from '../../assets/images/solutions/anonymity/description_2.svg';
import Description3 from '../../assets/images/solutions/anonymity/description_3.svg';
import Description4 from '../../assets/images/solutions/anonymity/description_4.svg';
import block1Src from '../../assets/images/solutions/anonymity/block_1.png';
import block1Src2x from '../../assets/images/solutions/anonymity/block_1_2x.png';
import block2Src from '../../assets/images/solutions/anonymity/block_2.png';
import block2Src2x from '../../assets/images/solutions/anonymity/block_2_2x.png';
import block3Src from '../../assets/images/solutions/anonymity/block_3.png';
import block3Src2x from '../../assets/images/solutions/anonymity/block_3_2x.png';
import ImageContainer from '../../components/ImageContainer/ImageContainer';
import BlockTitle from '../../components/BlockTitle/BlockTitle';
import FaqAnonymity from '../../components/FaqAnonymity/FaqAnonymity';

const AnonymityPage = (): JSX.Element => {
  const head = {
    title: <Trans>Анонимность</Trans>,
    text: (
      <Trans>
        Лучший способ оставаться анонимным в сети — это изменить не только IP-адрес, но и отпечаток браузера, который отслеживают сайты. Octo Browser — универсальный и быстрый антидетект браузер, который позволяет использовать неограниченное количество виртуальных профилей. С ним отследить ваш реальный фингерпринт невозможно.
      </Trans>
    ),
    images: [
      {
        image: <ImageContainer src={headSrc} srcSet={{ _2x: headSrc2x }} />,
      },
    ],
  };

  const descriptions = [
    {
      icon: <Description1 />,
      title: <Trans>Качественные подмены фингерпринта</Trans>,
      text: (
        <Trans>
          Обеспечьте полную анонимность в сети подменой всех отслеживаемых параметров отпечатка браузера. Ваши реальные данные невозможно скомпрометировать.
        </Trans>
      ),
    },
    {
      icon: <Description2 />,
      title: <Trans>Прохождение чекеров</Trans>,
      text: (
        <Trans>
          Убедитесь в отсутствии даже незначительных утечек данных на таких популярных чекерах, как Pixelscan, BrowserLeaks, CreepJS, Whoer и IP-API.
        </Trans>
      ),
    },
    {
      icon: <Description3 />,
      title: <Trans>Прокси</Trans>,
      text: (
        <Trans>
          Проверяйте работоспособность прокси-соединения прямо в Octo Browser. Поддержка всех популярных типов прокси и других сторонних решений по смене IP-адреса.
        </Trans>
      ),
    },
    {
      icon: <Description4 />,
      title: <Trans>Актуальная версия Chromium</Trans>,
      text: (
        <Trans>
          Сохраняйте эффект смешения с толпой после выхода новой версии Chromium. Команда Octo максимально оперативно обновляет ядро браузера.
        </Trans>
      ),
    },
  ];

  const blocks = {
    items: [
      {
        images: [
          {
            image: (
              <ImageContainer src={block1Src} srcSet={{ _2x: block1Src2x }} />
            ),
          },
        ],
        title: <Trans>Разделение сервисов</Trans>,
        text: (
          <Trans>
            Используйте разные виртуальные профили для получения доступа к разным интернет-сервисам. Установить, что их использует один человек, невозможно.
          </Trans>
        ),
      },
      {
        images: [
          {
            image: (
              <ImageContainer src={block2Src} srcSet={{ _2x: block2Src2x }} />
            ),
          },
        ],
        title: <Trans>Мультиаккаунтинг</Trans>,
        text: (
          <Trans>
            Воспользуйтесь всеми преимуществами мультиаккаунтинга без рисков разоблачения и блокировки.
          </Trans>
        ),
      },
      {
        images: [
          {
            image: (
              <ImageContainer src={block3Src} srcSet={{ _2x: block3Src2x }} />
            ),
          },
        ],
        title: <Trans>Безопасный скрейпинг</Trans>,
        text: (
          <Trans>
            Сохраняйте анонимность при получении данных из любых открытых источников. Ваша активность не выделяется среди действий обычных пользователей.
          </Trans>
        ),
      },
    ],
    title: (
      <BlockTitle
        highlightText={<><Trans>Возможности анонимной активности</Trans>{' '}</>}
        title={<Trans>с Octo Browser</Trans>}
        maxWidthTitle={800}
        highlightPosition="start"
      />
    ),
  };
  const faq = (
    <FaqAnonymity
      title={
        <BlockTitle
          title={<Trans>в сети</Trans>}
          highlightText={<><Trans>Как обеспечить анонимность</Trans>{' '}</>}
          maxWidthTitle={775}
          highlightPosition="start"
        />
      }
    />
  );
  return (
    <Layout>
      <AppRating />
      <SolutionLayout
        head={head}
        descriptions={descriptions}
        blocks={blocks}
        faq={faq}
      />
      <DownBlock />
    </Layout>
  );
};

export default AnonymityPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["anonymityPage", "translation"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
